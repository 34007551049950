<template>
  <footer class="text-white bg-black">
    <div class="container">
      <div class="flex flex-wrap items-center justify-between gap-5 py-14">
        <NuxtLink href="/" class="-m-1.5 p-1.5">
          <h1 class="sr-only">Driveczech</h1>
          <img class="w-auto h-16 invert" src="/images/driveczech-logo-black.svg" alt="Driveczech" />
        </NuxtLink>

        <div class="flex flex-col gap-2">
          <a href="tel:+420603427002" class="flex items-center gap-3" aria-label="Phone">
            <UIcon name="i-heroicons-phone-20-solid" />
            +420 603 427 002
          </a>
          <a href="mailto:info@driveczech.com" class="flex items-center gap-3" aria-label="E-mail">
            <UIcon name="i-heroicons-envelope" />
            info@driveczech.com
          </a>
          <div class="flex gap-4 mt-2">
            <a href="https://www.facebook.com/driveczech" aria-label="Facebook" target="_blank"><UIcon name="i-simple-icons-facebook" /></a>
            <a href="https://www.instagram.com/driveczech/" aria-label="Instagram" target="_blank"><UIcon name="i-simple-icons-instagram" /></a>
            <a href="https://www.tripadvisor.com/Attraction_Review-g274707-d10898456-Reviews-Driveczech_Your_Personal_Driver-Prague_Bohemia.html" aria-label="Tripadvisor" target="_blank"><UIcon name="i-simple-icons-tripadvisor" /></a>
            <a href="https://www.youtube.com/@driveczech" aria-label="Youtube" target="_blank"><UIcon name="i-simple-icons-youtube" /></a>
            <a href="https://www.linkedin.com/company/driveczech" aria-label="LinkedIn" target="_blank"><UIcon name="i-simple-icons-linkedin" /></a>
            <a href="https://g.page/driveczech" aria-label="Google Maps" target="_blank"><UIcon name="i-simple-icons-googlemaps" /></a>
          </div>
        </div>
      </div>

      <hr class="border-white/20">

      <h2 class="sr-only">Navigation</h2>
      <nav class="flex flex-wrap gap-x-32 gap-y-6 py-14">
        <div v-for="menu in navigation">
          <h3 class="mb-4 text-lg font-semibold">{{ menu.title }}</h3>
          <ul class="flex flex-col gap-2 text-sm font-light text-gray-300">
            <li v-for="item in menu.subitems">
              <NuxtLink :to="item.to">{{ item.title }}</NuxtLink>
            </li>
          </ul>
        </div>
      </nav>

      <hr class="border-white/20">

      <div class="flex flex-wrap items-center justify-between gap-5 font-serif text-xs text-gray-400 py-14">
        © Driveczech 2016-{{ new Date().getFullYear() }}
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const navigation = useMenuItems()
</script>
